<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>User Sub Category Add</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="item.name"
                    label="Name"
                    outlined
                    dense
                    placeholder="Name"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="cats" label="Category" item-text="cname" item-value="id" v-model="item.cat_id" dense outlined></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    @change="setImg"
                    :rules="[rules.required]"
                    v-model="item.new_image"
                    label="Image"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    max-height="150"
                    contain
                    :src="item.image"
                    v-if="item.image"
                  ></v-img>
                </v-col>

                <v-col cols="12">
                  <v-btn @click="submit" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'UserSubCategoryEdit',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {
        cname: null,
        image: null,
        new_image: null
      },
      cats: [],
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      var url = 'user-sub-categories?page=1'
      this.$api.get(url).then(response => {
        this.cats = response.data.user_categories
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.image = null
      } else {
        this.item.image = URL.createObjectURL(this.item.new_image)
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post('user-sub-categories', formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'UserCategoryList' })
          })
      }
    },
  },
}
</script>
